@tailwind base;
@tailwind components;
@tailwind utilities;

.placeholder::after {
    content: ' ';
    box-shadow: 0 0 50px 9px rgba(254, 254, 254);
    position: absolute;
    top: 0;
    left: -100%;
    height: 100%;
    animation: load 2s infinite;
}

@keyframes load {
    0% {
        left: -100%;
    }
    100% {
        left: 150%;
    }
}

/*https://stackoverflow.com/questions/51334960/how-to-show-calendar-popup-when-inputtype-date-is-on-focus*/
input[type='date'] {
    box-sizing: border-box;
    position: relative;
    width: 100%;
}

input[type='date']::-webkit-calendar-picker-indicator {
    background: transparent;
    color: transparent;
    cursor: pointer;
    height: auto;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    position: absolute;
    width: auto;
}

[data-popper-arrow] {
    top: -6.5px;
}

[data-popper-arrow]:after,
[data-popper-arrow]:before {
    border-bottom-width: 1px;
    border-right-width: 1px;
    background: #052e16;
}

[data-popper-arrow][data-bg='dark']:before,
[data-popper-arrow][data-bg='dark']:after {
    background: #e5e7eb;
}

[data-popper-arrow]:before {
    border-color: #e5e7eb;
    border-style: solid;
}
[data-popper-arrow]:after {
    border-color: #e5e7eb;
    border-style: solid;
}

[data-popper-arrow]:after,
[data-popper-arrow]:before {
    content: '';
    transform: rotate(225deg);
    visibility: visible;
}

[data-popper-arrow]:after {
    background: inherit;
    height: 12px;
    position: absolute;
    width: 12px;
}

[data-popper-arrow],
[data-popper-arrow]:before {
    height: 12px;
    position: absolute;
    width: 12px;
}
